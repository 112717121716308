<template>
  <div class="questionnaire-content">
    <el-form :model="searchForm" label-width="125px" :inline="true">
      <el-form-item label="问卷">
        <el-input v-model="searchForm.name" class="width-220">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchInitFn">查找</el-button>
        <el-button type="primary" @click="dialogVisible = true">创建试卷</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData" v-loading="tableLoading"
      style="width: 100%; margin-bottom: 22px;">
      <el-table-column type="index" :index="setIndexFn" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="name" label="问卷名称">
      </el-table-column>
      <el-table-column prop="create_time" label="时间" width="150">
        <template slot-scope="scope">
          {{timStampToChar(scope.row.create_time)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="showFn(scope.row)">查看</el-button>
          <el-button type="text" @click="editFn(scope.row)">编辑</el-button>
          <el-button type="text" @click="deleteFn(scope.row)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="pagination.current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
    <el-dialog
      :title="title" width="560px"
      @close="dialogVisible = false"
      v-loading="dialogLoading"
      :append-to-body="true"
      :visible.sync="dialogVisible">
      <el-form
        :model="dialogForm" :rules="rules"
        label-width="80px" ref="dialogForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="dialogForm.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="confirmFn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import papers from '@/api/papers';
import { timStampToChar } from '@/utils/common';

export default {
  data() {
    return {
      tableLoading: false,
      dialogVisible: false,
      dialogLoading: false,
      title: '',
      current: null,
      tableData: [],
      obj: {
        True: '正确',
        False: '错误',
      },
      searchForm: {
        questionnaireName: '',
        startTime: '',
        endTime: '',
      },
      dialogForm: {
        name: '',
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur',
        }],
        type: [{
          required: true,
          message: '请选择类型',
          trigger: 'change',
        }],
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.title = '创建问卷';
        if (this.current) {
          this.title = '编辑问卷';
          this.$nextTick(() => {
            Object.assign(this.dialogForm, this.current);
          });
        }
      } else {
        this.$refs.dialogForm.resetFields();
        this.current = null;
      }
    },
  },
  mounted() {
    this.searchFn();
  },
  methods: {
    timStampToChar,
    searchFn() {
      this.tableLoading = true;
      papers.get({
        ...this.searchForm,
        ...this.pagination,
      }).then(({ data, total }) => {
        this.tableLoading = false;
        this.tableData = data.data;
        this.pagination.total = total ? Number(total) : 0;
      }).catch(() => {
        this.tableLoading = false;
        this.tableData = [];
        this.pagination.total = 0;
      });
    },
    confirmFn() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let request = papers.add;
          let obj = this.dialogForm;
          if (this.current) {
            request = papers.put;
            obj = { id: this.current.id, ...this.dialogForm };
          }
          this.dialogLoading = true;
          request(obj).then(() => {
            this.dialogLoading = false;
            this.dialogVisible = false;
            this.searchInitFn();
            this.$message.success(`${this.title}成功`);
          }).catch(() => {
            this.dialogLoading = false;
            this.$message.error(`${this.title}失败`);
          });
        }
      });
    },
    searchInitFn() {
      this.pagination.current = 1;
      this.searchFn();
    },
    showFn(row) {
      console.log(row);
    },
    editFn(row) {
      this.current = { ...row };
      this.dialogVisible = true;
    },
    deleteFn({ name, id }) {
      this.$confirm(`确定要删除'${name}'问卷吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.tableLoading = true;
        papers.delete(id).then(() => {
          this.tableLoading = false;
          this.searchInitFn();
          this.$message.success('试卷删除成功');
        }).catch(() => {
          this.tableLoading = false;
          this.$message.error('试卷删除失败');
        });
      }).catch(() => {
        this.$message.info('已取消删除试卷');
      });
    },
    setIndexFn(index) {
      const { current, pageSize } = this.pagination;
      return (current - 1) * pageSize + index + 1;
    },
    sizeChange(val) {
      this.pagination.current = 1;
      this.pagination.pageSize = val;
      this.searchFn();
    },
    currentChange(val) {
      this.pagination.current = val;
      this.searchFn();
    },
  },
};
</script>

<style scoped lang="less">
</style>
